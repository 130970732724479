import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form from '@atlaskit/form'

import ConfigTableEditor from '@components/Config/ConfigTableEditor'
import ScreenConfigEditor from '@components/Config/ScreenConfigEditor'

import Store from './Config.store'
import flagStore from '@stores/flagStore'

const ConfigTableEditorModal = ({
  configLevel,
  closeModal,
  update,
  cumulativeConfig,
  nodeId,
  configPath,
  editable = true,
  modalWidth,
  saveCallBack = () => {
    //noop
  },
}) => {
  const saveConfig = useCallback(async () => {
    const config = cumulativeConfig[configLevel]
    if (
      config &&
      config.config &&
      config.config.betPlacementFreeText &&
      config.config.betPlacementFreeText.length > 80
    ) {
      flagStore.addFlag({
        title: `Bet Placement Free Text shouldn't exceed 80 characters.`,
        type: 'error',
        description: 'TEST',
      })
      return
    }
    config[`${configLevel.toLowerCase()}Id`] = nodeId
    await Store.saveConfig(config)
    saveCallBack()
    closeModal()
  }, [JSON.stringify(cumulativeConfig), nodeId])

  const actions = [
    {
      text: 'Save Config',
      onClick: saveConfig,
    },
    { text: 'Close', onClick: closeModal },
  ]

  Store.getAvailableConfigVersions()

  return (
    <Modal
      actions={actions}
      heading="Edit Config"
      onClose={closeModal}
      width={modalWidth || 'large'}
    >
      {configLevel === 'screen' ? (
        <Form shouldFitContainer>
          <ScreenConfigEditor update={update} config={cumulativeConfig} />
          <ConfigTableEditor
            config={cumulativeConfig}
            configLevel={configLevel}
            update={update}
            configPath={configPath}
            editable={editable}
          />
        </Form>
      ) : (
        <Form shouldFitContainer>
          <ConfigTableEditor
            config={cumulativeConfig}
            configLevel={configLevel}
            update={update}
            configPath={configPath}
            editable={editable}
          />
        </Form>
      )}
    </Modal>
  )
}

const configPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
})

ConfigTableEditorModal.propTypes = {
  editable: PropTypes.bool,
  configPath: PropTypes.string.isRequired,
  configLevel: PropTypes.oneOf(['organisation', 'region', 'location', 'screen'])
    .isRequired,
  closeModal: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  cumulativeConfig: PropTypes.shape({
    organisation: configPropType,
    region: configPropType,
    location: configPropType,
    screen: configPropType,
  }),
  modalWidth: PropTypes.string,
  saveCallBack: PropTypes.func,
}

export default observer(ConfigTableEditorModal)

import { types, flow } from 'mobx-state-tree'
import rest from '@utils/rest'
import makeInspectable from 'mobx-devtools-mst'
import set from 'lodash/set'
import pickBy from 'lodash/pickBy'
import { configSchema } from '../../schema/config.schema.js'

const {
  model,
  string,
  number,
  boolean,
  maybeNull,
  array,
  identifier,
  enumeration,
  safeReference,
  optional,
  union,
} = types

export const BaseConfig = model('BaseConfig', {
  bookmakerId: maybeNull(string),
  ghBookmakerId: maybeNull(string),
  contentId: maybeNull(string),
  screenType: optional(
    enumeration('ScreenType', ['LARGE_4K_SCREEN', 'SMALL_HD_SCREEN']),
    'LARGE_4K_SCREEN',
  ),
  showPrices: maybeNull(boolean),
  inlineSpotlights: maybeNull(boolean),
  diomedSpotlights: maybeNull(boolean),
  countries: maybeNull(string),
  showRPZone: maybeNull(boolean),
  highlightToteRaces: maybeNull(boolean),
  verdictAtBottom: maybeNull(boolean),
  showVirtuals: maybeNull(boolean),
  display24Hr: maybeNull(boolean),
  showGreyhounds: maybeNull(boolean),
  showGhForecast: maybeNull(boolean),
  showAdverts: maybeNull(boolean),
  showScreensaver: maybeNull(boolean),
  showNewspaper: maybeNull(string),
  defaultNewspaperPage: maybeNull(string),
  showCardInsights: maybeNull(boolean),
  showNewspaperTips: maybeNull(boolean),
  refreshBetweenTimes: maybeNull(string),
  derivedMarkets: maybeNull(boolean),
  devTools: maybeNull(boolean),
  showFeatureRacesByDay: maybeNull(string),
  screenDisplayType: maybeNull(string),
  showEPS: maybeNull(boolean),
  showHorsesNextOff: maybeNull(boolean),
  meetingPriority: maybeNull(string),
  morningMeetingPriority: maybeNull(string),
  morningMeetingPriorityStartTime: maybeNull(string),
  afternoonMeetingPriority: maybeNull(string),
  afternoonMeetingPriorityStartTime: maybeNull(string),
  eveningMeetingPriority: maybeNull(string),
  eveningMeetingPriorityStartTime: maybeNull(string),
  showMeetingPriorityByTime: maybeNull(boolean),
  version: maybeNull(string),
  showEasyView: maybeNull(string),
  defaultToEasyView: maybeNull(boolean),
  showSplashPage: maybeNull(boolean),
  showGreyhoundsEasyView: maybeNull(boolean),
  testScreen: maybeNull(boolean),
  screenNumber: maybeNull(number),
  showSports: maybeNull(boolean),
  sportsNavigationState: maybeNull(string),
  specificSport: maybeNull(string),
  sportsDaysToAdd: maybeNull(number),
  sportsOrder: maybeNull(string),
  runnerSortBy: maybeNull(string),
  isIREShop: maybeNull(boolean),
  betPlacementFreeText: maybeNull(string),
})

export const ConfigModel = model('ConfigModel', {
  id: identifier,
  config: BaseConfig,
})

export const defaultConfig = (id = '') => ({
  id,
  config: {
    bookmakerId: null,
    ghBookmakerId: null,
    contentId: null,
    screenType: 'LARGE_4K_SCREEN',
    showPrices: null,
    inlineSpotlights: null,
    diomedSpotlights: null,
    countries: null,
    showRPZone: null,
    highlightToteRaces: null,
    verdictAtBottom: null,
    showVirtuals: null,
    display24Hr: null,
    showGreyhounds: null,
    showGhForecast: null,
    showAdverts: null,
    showScreensaver: null,
    showNewspaper: null,
    defaultNewspaperPage: null,
    showCardInsights: null,
    showNewspaperTips: null,
    refreshBetweenTimes: null,
    derivedMarkets: null,
    devTools: null,
    showFeatureRacesByDay: null,
    screenDisplayType: null,
    showEPS: null,
    showHorsesNextOff: null,
    meetingPriority: null,
    morningMeetingPriority: null,
    morningMeetingPriorityStartTime: null,
    afternoonMeetingPriority: null,
    afternoonMeetingPriorityStartTime: null,
    eveningMeetingPriority: null,
    eveningMeetingPriorityStartTime: null,
    showMeetingPriorityByTime: null,
    version: '',
    showEasyView: null,
    defaultToEasyView: null,
    showSplashPage: null,
    showGreyhoundsEasyView: null,
    testScreen: null,
    screenNumber: null,
    showSports: null,
    sportsNavigationState: null,
    specificSport: null,
    sportsDaysToAdd: null,
    sportsOrder: null,
    runnerSortBy: null,
    isIREShop: null,
    betPlacementFreeText: null,
  },
})

const Screen = model('Screen', {
  id: string,
  name: string,
  config: maybeNull(ConfigModel),
})

const LocalLocation = model('Location', {
  id: string,
  name: string,
  config: maybeNull(ConfigModel),
  screens: array(Screen),
  shopId: string,
  city: string,
  postCode: string,
})

const Region = model('Region', {
  id: string,
  name: string,
  config: maybeNull(ConfigModel),
  locations: array(LocalLocation),
})

const Organisation = model('Organisation', {
  id: string,
  name: string,
  config: maybeNull(ConfigModel),
  regions: array(Region),
})

export const MaybeConfigReferenceType = (id) =>
  optional(union(safeReference(ConfigModel), ConfigModel), defaultConfig(id))

const ModalData = model('ModalData', {
  nodeId: string,
  cumulativeConfig: model('Configs', {
    organisation: MaybeConfigReferenceType('organisation'),
    region: MaybeConfigReferenceType('region'),
    location: MaybeConfigReferenceType('location'),
    screen: MaybeConfigReferenceType('screen'),
  }),
  editable: boolean,
  configLevel: maybeNull(string),
})

const ConfigStore = model('ConfigStore', {
  organisations: array(Organisation),
  modalOpen: boolean,
  modalData: maybeNull(ModalData),
  addScreenModalOpen: boolean,
  addScreenModalData: maybeNull(
    model({ organisationId: string, regionId: string, locationId: string }),
  ),
  availableConfigVersions: array(string),
})
  .views((self) => ({
    get organisationConfig() {
      return self.config.filter((config) => !!config.organisationId)
    },
    get screenConfig() {
      return self.config.filter((config) => !!config.screenId)
    },
  }))
  .actions((self) => ({
    updateFormRef(ref) {
      self.modalRef = ref
    },
    update(event) {
      if (event.target.name.includes('showEasyView')) {
        const partsOfTarget = event.target.name.split('.')
        const lastIndex = partsOfTarget.length - 1

        partsOfTarget[lastIndex] = 'showGreyhoundsEasyView'

        const newKey = partsOfTarget.join('.')

        if (event.target.value === 'off') {
          set(self.modalData, newKey, false)
        }
      }

      if (event.target.name.includes('showGreyhoundsEasyView')) {
        const partsOfTarget = event.target.name.split('.')
        const lastIndex = partsOfTarget.length - 1

        partsOfTarget[lastIndex] = 'showEasyView'

        const newKey = partsOfTarget.join('.')
        if (event.target.value === true || event.target.value === 'true') {
          if (
            self.modalData.cumulativeConfig[partsOfTarget[1]].config
              .showEasyView !== 'easyView' // Don't overwrite if it already has a value
          ) {
            set(self.modalData, newKey, 'smartView')
          }
        }
      }
      set(self.modalData, event.target.name, event.target.value)
    },

    openAddScreenModal(data) {
      self.addScreenModalOpen = true
      self.addScreenModalData = data
    },
    closeAddScreenModal() {
      self.addScreenModalOpen = false
      self.addScreenModalData = null
    },

    openModal(data) {
      self.modalOpen = true
      self.modalData = data ? { ...data } : null
    },
    closeModal() {
      self.modalOpen = false
      self.modalData = null
    },
    getAvailableConfigVersions: flow(function* getavailableConfigVersions() {
      try {
        const res = yield rest({
          url: 'config-versions',
        })
        self.availableConfigVersions = res
      } catch (e) {
        console.log(e)
      }
    }),
    getOrganisations: flow(function* getOrganisations() {
      try {
        const res = yield rest({
          url: 'organisation',
        })
        self.organisations = res
      } catch (e) {
        console.log(e)
      }
    }),
    saveConfig: flow(function* saveConfig(config) {
      try {
        const cleanConfig = {
          ...config,
          config: pickBy(config.config, (val) => val !== null),
        }
        if (cleanConfig.config) {
          if (!cleanConfig.config.contentId && cleanConfig.config.bookmakerId) {
            cleanConfig.config.contentId = cleanConfig.config.bookmakerId
          }
        }
        yield configSchema.validate(cleanConfig.config, {
          strict: true,
        })

        const fakeIds = ['organisation', 'region', 'location', 'screen']
        if (
          cleanConfig.id &&
          cleanConfig.id.length > 0 &&
          !fakeIds.includes(cleanConfig.id)
        ) {
          yield self.updateConfig(cleanConfig)
        } else {
          yield self.createConfig(cleanConfig)
        }

        self.getOrganisations()
        self.closeModal()
      } catch (error) {
        console.error(`Failed to save Config ${config.id}`, error)
        return error
      }
    }),

    createConfig: flow(function* createConfig(config) {
      const cleanConfig = { ...config }
      delete cleanConfig.id
      const options = {
        method: 'POST',
        url: `config`,
        body: cleanConfig,
        successMessage: `Successfully Added Config`,
      }
      yield rest(options)
    }),

    updateConfig: flow(function* updateConfig(config) {
      const options = {
        method: 'PATCH',
        url: `config/${config.id}`,
        body: config,
        successMessage: `Successfully Updated Config ${config.id}`,
      }
      yield rest(options)
    }),

    deleteConfig: flow(function* deleteConfig(id) {
      try {
        yield rest({
          url: `config/${id}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        self.getOrganisations()
      } catch (error) {
        console.error(`Failed to Delete Config ${id}`, error)
      }
    }),
  }))
  .create({
    organisations: [],
    modalOpen: false,
    modalData: null,
    addScreenModalOpen: false,
    contentIds: [],
    availableConfigVersions: [],
  })

makeInspectable(ConfigStore)

export default ConfigStore

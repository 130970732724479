const MODAL_ZINDEX = 9999

export { MODAL_ZINDEX }

export const bookmakerOptionsForOrganisationId = {
  1: ['Korelogic'],
  2: ['Racing_Post'],
  3: ['CORAL', 'CORAL_GB'],
  '15d92f82-7a34-4aba-a22a-c773a63515da': ['LADB_GB', 'LADB_IRE', 'LADB'],
  'da6726a5-bb83-40b3-812d-5c21d97b6126': ['LADB_GB', 'LADB_IRE', 'LADB'],
  'b0ba5487-953d-436b-ae29-6f9ce339eaae': ['LADB_GB', 'LADB_IRE', 'LADB'],
  '89add211-df82-4612-a780-fbaa406ee99c': ['LADB_GB', 'LADB_IRE', 'LADB'],
  '2634a3e0-1329-432b-9cf2-7e9aeedd0b12': ['BETFRED, BETFRED_RETAIL'],
  'f1b59797-c516-44ab-a1fc-c7d97c8fce81': ['BOLEYSPORTS'],
  '3d8e276f-6e9b-4c28-90f2-49c4f38f3b67': ['WH_RETAIL', 'WH_OXI'],
  '9c41b910-6085-49ab-93df-b294912f9a52': ['BARONE'],
  'f42de15b-205c-44e6-8308-bd72e093fe8e': ['TOALS'],
  '1f403f95-0fd8-4be1-9d2d-f7dc8802b99c': ['BETABET'],
  '70966f72-a6ba-4429-a299-4660e94e16ee': ['JENNINGSBET'],
  '4df17ea2-54a8-45ff-89b4-560611d0dfb0': ['PADDYPOWER'],
}

export const contentIds = [
  'ASPERS',
  'BARONE',
  'BETABET',
  'BETFRED',
  'BETFRED_RETAIL',
  'BOLEYSPORTS',
  'CORAL',
  'CORAL_GB',
  'CORAL_IRE',
  'DON_NOBLE',
  'EARLY_BIRD',
  '#INFO',
  'IRACE',
  'JENNINGSBET',
  'LADB',
  'LADB_GB',
  'LADB_IRE',
  'MCCARTANBET',
  'ORENES',
  'PADDYPOWER',
  'PLAYTECH',
  'PPWR',
  'RACING_POST',
  'RB',
  'SURREY',
  'TOALS',
  'TOMMY_FRENCH',
  'TRACKSPORTS',
  'WH_OXI',
  'WH_RETAIL',
]

export const entainOrgIds = [
  '1', // Korelogic
  '3', // Coral
  '15d92f82-7a34-4aba-a22a-c773a63515da', // Ladbrokes Ire
  'da6726a5-bb83-40b3-812d-5c21d97b6126', // Ladbroker Coral Group
  'b0ba5487-953d-436b-ae29-6f9ce339eaae', // Labdrokes Coral Retail
  '89add211-df82-4612-a780-fbaa406ee99c', // Ladbrokes Tv
]
